import React from 'react'
import { Link } from 'gatsby'

import PlanItem from '../plan-item'

import './index.scss'

export default ({ planTitle, showPlan, storeURL }) => {
  return (
    showPlan && (
      <div className="check-box">
        <Link to="/plan">
          <span className="plan-title">{planTitle} 🗣️</span>
        </Link>
        <a href={storeURL}>
          <button className="store-link-button">
            Your mask speaks. Make sure you believe in what it says.
          </button>
        </a>
        {/* <PlanItem
        // planTitle={planTitle}
        /> */}
      </div>
    )
  )
}
